import React from 'react';
import analysingSVG from '../../assets/img/analysing.svg';
import moneySVG from '../../assets/img/money.svg';
import timeSVG from '../../assets/img/time.svg';

import './StaticSections.scss';

const StaticSections = () => {
  return (
    <>
      <section className="goals">
        <div className="container goalsContainer">
          <h2 className="goalsTitle">Rent with confidence</h2>
          <p className="goalsDescription">
            We&apos;re your first stop when looking for your next rental home so you can...
          </p>
          <div className="goalsContent">
            <div className="goalsContentItem">
              <img src={timeSVG} alt="save time" />
              <h4>Save time</h4>
              <p>
                Don&apos;t waste time searching in areas that are outside your budget. Find the
                place that works best for you and your budget.
              </p>
            </div>
            <div className="goalsContentItem">
              <img src={moneySVG} alt="pay price" />
              <h4>Pay a fair price</h4>
              <p>
                The use of pricing software is becoming increasingly popular with small and big
                landlords alike, causing rent prices to fluctuate daily. Make an educated rental
                decision by using our data to ensure you&apos;re paying a fair rent price.
              </p>
            </div>
            <div className="goalsContentItem">
              <img src={analysingSVG} alt="prepered" />
              <h4>Be prepared</h4>
              <p>
                Renting is an important personal, financial, and legal decision. With our data in
                hand, you can make the right decision with confidence.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="subscribe">
        <div className="container subscribeContainer">
          <h3 className="subscribeTitle">Sign up for our newsletter</h3>
          <div className="subscribeSubTitle">
            Get rental price reports delivered straight to your inbox
          </div>
          <form
            target="_blank"
            action="//rentometer.us5.list-manage.com/subscribe/post?u=a24167d46e9f62b58b2bfde42&amp;id=78562da11f"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate">
            <div className="subscribeGroup">
              <input
                className="subscribeInput"
                type="email"
                name="EMAIL"
                placeholder="Email"
                required
              />
              <input className="subscribeButton" type="submit" value="Subscribe" name="subscribe" />
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default StaticSections;
