import React, { useState } from 'react';
import './ErrorMapModal.scss';

const ErrorMapModal = ({ isError, modal, recalculate, disableRecalculate, percentile25 }) => {
  const modalDisplay = isError && !modal ? 'block' : 'none';

  return (
    <div className="resultMapError" style={{ display: modalDisplay }}>
      <div className="resultMapErrorTitle">Budget Too Low</div>
      <div className="resultMapErrorDesc">
        Oops! It seems like the budget you entered is too low to show any data. To see results,
        please increase your budget.
      </div>
      <div className="resultMapErrorDesc">
        A reasonable budget starts at{' '}
        <strong>${Math.round(percentile25).toLocaleString().split(/\s/).join(',')}</strong>
      </div>
      <button className="resultMsgBtn" onClick={recalculate} disabled={!disableRecalculate}>
        Recalculate
      </button>
    </div>
  );
};

export default ErrorMapModal;
