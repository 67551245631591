import React from 'react';
import homeImage from '../../assets/img/homeBanner.png';

import './HomeSection.scss';

const HomeSection = () => {
  return (
    <section className="home">
      <img className="homeBanner" src={homeImage} alt="" />
      <div className="container homeContainer">
        <div className="homeContentLeft">
          <h1>Discover areas to rent within your budget</h1>
          <h5>Check rent prices instantly across the U.S.</h5>
          <p>
            Make smart rental decisions with Compare My Rent, powered by Rentometer - the leading
            source of nationwide rent data. Identify where you could live based on your budget and
            current rent prices.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HomeSection;
