import React, { FunctionComponent } from 'react';
import './ErrorMessage.scss';

import { ReactComponent as ErrorSVG } from '../../assets/img/error.svg';

interface ErrorMessageProps {
  recalculate?: () => void;
  disableRecalculate?: boolean;
}

const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({
  recalculate,
  disableRecalculate
}) => {
  return (
    <div className="error">
      <div className="errorIcon">
        <ErrorSVG />
      </div>
      <div className="errorText">
        <div className="errorTitle">We have a little problem. </div>
        <div className="errorSubTitle">Looks like your search was not rent to be.</div>
        <div className="errorDescription">
          Looks like we don&apos;t have enough rental data points to match your search criteria.
          This can happen if you&apos;re searching a younger rental market or an area with fewer
          rental properties. Please expand your filters and try again.
        </div>
        {recalculate && (
          <button className="errorRecalculate" onClick={recalculate} disabled={!disableRecalculate}>
            Recalculate
          </button>
        )}
      </div>
    </div>
  );
};

export default ErrorMessage;
