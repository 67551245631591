import React, { useEffect, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button
} from '@mui/material';

import { ReactComponent as StreetViewSVG } from '../../assets/img/street.svg';
import { ReactComponent as BlueSVG } from '../../assets/img/blueTable.svg';
import { ReactComponent as GreenSVG } from '../../assets/img/greenTable.svg';
import { ReactComponent as GoogleMapSVG } from '../../assets/img/googleMapIcon.svg';
import { ReactComponent as SpecialStarSVG } from '../../assets/img/specialStar.svg';
import { ReactComponent as WebSVG } from '../../assets/img/web.svg';
import { ReactComponent as MailSVG } from '../../assets/img/mail.svg';
import {
  placeholderData,
  placeholderCheapestData,
  columns,
  columnsSpecial
} from './ListingTable.data';

import ListingCard from '../ListingCard/ListingCard.component';
import CustomSlides from '../ListingSlider/ListingSlider.component';
import useMediaQuery from '../../hooks/useMediaQuery';
import compareDates from '../../helpers/compareDates';
import SpecialCard from '../SpecialCard/SpecialCard.component';

function getFormattedDate(currDate) {
  let date = new Date(currDate);
  let year = date.getFullYear().toString().slice(2, 4);
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');

  return month + '/' + day + '/' + year;
}

const sortByMostRecent = (data) => {
  return data.sort((a, b) => {
    const dateA = new Date(a.last_seen);
    const dateB = new Date(b.last_seen);

    return dateB.getTime() - dateA.getTime();
  });
};

const getMostRecentItems = (data) => {
  const blueItems = data.filter((item) => item.flag === 'blue');
  const greenItems = data.filter((item) => item.flag === 'green');

  const mostRecentBlueItems = sortByMostRecent(blueItems).slice(0, 5);
  const remainingSpaces = 5 - mostRecentBlueItems.length;

  const additionalGreenItems = sortByMostRecent(greenItems).slice(0, remainingSpaces);

  return [...mostRecentBlueItems, ...additionalGreenItems];
};

const ListingTable = ({
  data = placeholderData,
  dataCheapest = placeholderCheapestData,
  setPanorama,
  panorama,
  handleTableTypeChange,
  tableType,
  totalPay = 0,
  avarege = 0,
  proptypeOrigin,
  specialData
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  const sortedNewSpecialData = useMemo(() => {
    const filteredSpecialData = specialData.filter((item) => item.process_state === 'NEW SPECIAL');
    const sortedNewSpecialData = filteredSpecialData.sort(compareDates);
    return sortedNewSpecialData.slice(0, 5);
  }, [specialData]);

  useEffect(() => {
    if (dataCheapest.length === 0) {
      handleTableTypeChange('closest');
    }
  }, []);

  let rows;

  if (tableType === 'closest') {
    if (data) {
      const lowestPriceProperties = getMostRecentItems(data);
      rows = lowestPriceProperties.map((property) => {
        const {
          address,
          latitude,
          longitude,
          distance,
          price,
          bedrooms,
          baths,
          property_type,
          last_seen,
          sqft,
          dollar_sqft,
          flag
        } = property;

        return {
          address,
          propertyType: property_type.charAt(0).toUpperCase() + property_type.slice(1) || 'Unknown',
          distance,
          rawPrice: price,
          price: price ? '$' + price.toLocaleString().split(/\s/).join(',') : '-',
          bedrooms,
          bathrooms: baths ? baths.replace('ba', '') : '-',
          listDate: last_seen ? getFormattedDate(last_seen) : '-',
          size: sqft ? sqft.toLocaleString().split(/\s/).join(',') : '-',
          priceSqft: dollar_sqft ? `$${dollar_sqft.toLocaleString().split(/\s/).join(',')}` : '-',
          latitude,
          longitude,
          flag
        };
      });
    }
  } else {
    if (dataCheapest) {
      rows = dataCheapest.map((property) => {
        const {
          address,
          latitude,
          longitude,
          distance,
          price,
          bedrooms,
          baths,
          property_type,
          last_seen,
          sqft,
          dollar_sqft,
          flag
        } = property;

        return {
          address,
          propertyType: property_type.charAt(0).toUpperCase() + property_type.slice(1) || 'Unknown',
          distance,
          rawPrice: price,
          price: price ? '$' + price.toLocaleString().split(/\s/).join(',') : '-',
          bedrooms,
          bathrooms: baths ? baths.replace('ba', '') : '-',
          listDate: last_seen ? getFormattedDate(last_seen) : '-',
          size: sqft ? sqft.toLocaleString().split(/\s/).join(',') : '-',
          priceSqft: dollar_sqft ? `$${dollar_sqft.toLocaleString().split(/\s/).join(',')}` : '-',
          latitude,
          longitude,
          flag
        };
      });
    }
  }

  return (
    <>
      <div className="tableType">
        <button
          className={`tableTypeItem ${tableType === 'cheapest' ? 'tableTypeActive' : null}`}
          onClick={() => handleTableTypeChange('cheapest')}>
          Least expensive
        </button>
        <button
          className={`tableTypeItem ${tableType === 'closest' ? 'tableTypeActive' : null}`}
          onClick={() => handleTableTypeChange('closest')}>
          Closest to budget
        </button>
        <button
          className={`tableTypeItemSpecial ${tableType === 'special' ? 'tableTypeActiveSpecial' : null}`}
          onClick={() => handleTableTypeChange('special')}>
          <SpecialStarSVG />
          Specials
        </button>
      </div>
      {tableType === 'special' ? (
        // sortedNewSpecialData.length !== 0 ? (
        //   <>
        //     {isMobile ? (
        //       // <CustomSlides>
        //       //   {sortedNewSpecialData.map((item, i) => (
        //       //     <SpecialCard
        //       //       key={i}
        //       //       card={item}
        //       //       panorama={panorama}
        //       //       setPanorama={setPanorama}
        //       //       totalPay={totalPay}
        //       //       avarege={avarege}
        //       //       proptype={proptypeOrigin}
        //       //     />
        //       //   ))}
        //       // </CustomSlides>
        //       <></>
        //     ) : (
        //       // <TableContainer className="tableContainer" component={Paper}>
        //       //   <Table className="table">
        //       //     <TableHead className="tableHead">
        //       //       <TableRow>
        //       //         {columnsSpecial.map((column, i) => (
        //       //           <TableCell key={i} className="tableHeadCell">
        //       //             {column.label}
        //       //           </TableCell>
        //       //         ))}
        //       //       </TableRow>
        //       //     </TableHead>
        //       //     <TableBody>
        //       //       {sortedNewSpecialData.map((row, i) => (
        //       //         <TableRow key={'row' + i} className="tableRow">
        //       //           {columnsSpecial.map((column, j) => {
        //       //             if (column.id === 'Web Address' || column.id === 'Contact Us URL') {
        //       //               return (
        //       //                 <TableCell key={'' + i + j} className="tableCell">
        //       //                   {/* <a href={row[column.id]} className="tableCellSpecialLink">
        //       //                 {row[column.id]}
        //       //               </a> */}
        //       //                   <Button
        //       //                     disabled={!row[column.id]}
        //       //                     sx={{
        //       //                       fontSize: '10px',
        //       //                       fontWeight: 500,
        //       //                       lineHeight: '12px',
        //       //                       border: '1px solid #D2DBE8',
        //       //                       color: '#2D3035',
        //       //                       minWidth: '120px'
        //       //                     }}
        //       //                     onClick={() => {
        //       //                       if (row[column.id]) {
        //       //                         window.open(row[column.id], '_blank');
        //       //                       }
        //       //                     }}>
        //       //                     {column.id === 'Web Address' ? (
        //       //                       <Box display="flex" alignItems="center" gap="5px">
        //       //                         <WebSVG /> Web Address
        //       //                       </Box>
        //       //                     ) : (
        //       //                       <Box display="flex" alignItems="center" gap="5px">
        //       //                         <MailSVG /> Contact us
        //       //                       </Box>
        //       //                     )}
        //       //                   </Button>
        //       //                 </TableCell>
        //       //               );
        //       //             }
        //       //             if (column.id === 'Formatted Address') {
        //       //               return (
        //       //                 <TableCell
        //       //                   key={'' + i + j}
        //       //                   className="tableCell"
        //       //                   style={{ minWidth: '250px' }}>
        //       //                   {row[column.id]}
        //       //                 </TableCell>
        //       //               );
        //       //             }
        //       //             return (
        //       //               <TableCell key={'' + i + j} className="tableCell">
        //       //                 {row[column.id]}
        //       //               </TableCell>
        //       //             );
        //       //           })}
        //       //         </TableRow>
        //       //       ))}
        //       //     </TableBody>
        //       //   </Table>
        //       // </TableContainer>
        //       <></>
        //     )}
        //   </>
        // ) : (
        //   <div className="tableEmpty">No records found</div>
        // )
        <Box height="200px" width="100%" display="flex" justifyContent="center" alignItems="center">
          <Box display="flex" justifyContent="center" alignItems="center" gap="5px">
            <SpecialStarSVG />
            <h5
              style={{
                fontSize: '18px',
                fontWeight: 500,
                lineHeight: '32px',
                color: '#EF6F22'
              }}>
              Specials information coming soon
            </h5>
          </Box>
        </Box>
      ) : isMobile ? (
        rows.length > 1 ? (
          <CustomSlides>
            {rows.map((row, i) => (
              <ListingCard
                key={i}
                card={row}
                panorama={panorama}
                setPanorama={setPanorama}
                totalPay={totalPay}
                avarege={avarege}
                proptype={proptypeOrigin}
              />
            ))}
          </CustomSlides>
        ) : (
          rows.map((row, i) => (
            <ListingCard
              key={i}
              card={row}
              panorama={panorama}
              setPanorama={setPanorama}
              totalPay={totalPay}
              avarege={avarege}
              proptype={proptypeOrigin}
            />
          ))
        )
      ) : rows ? (
        <TableContainer className="tableContainer" component={Paper}>
          <Table className="table">
            <TableHead className="tableHead">
              <TableRow>
                {columns.map((column, i) => (
                  <TableCell key={i} className="tableHeadCell">
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow key={i} className="tableRow">
                  {columns.map((column, j) => {
                    return (
                      <TableCell
                        key={j}
                        className="tableCell"
                        style={{ minWidth: j === 0 ? '200px' : 'unset' }}>
                        {j === 0 ? (
                          <div className="tableCellContainer">
                            <div className="tableCellStreet">
                              {row.flag === 'blue' ? <BlueSVG /> : <GreenSVG />}
                            </div>
                            <div className="tableCellContent">
                              <div className="tableCellAddress">
                                {String(row[column.id]).length > 40
                                  ? `${String(row[column.id]).substring(0, 40)}...`
                                  : row[column.id]}
                              </div>
                              <div className="tableCellIcons">
                                <div
                                  className={`tableCellIcon ${
                                    JSON.stringify(panorama) ==
                                    JSON.stringify({ lat: row.latitude, lng: row.longitude })
                                      ? 'tableCellIconBlue'
                                      : null
                                  }`}
                                  onClick={() => {
                                    if (
                                      JSON.stringify(panorama) ==
                                      JSON.stringify({ lat: row.latitude, lng: row.longitude })
                                    ) {
                                      setPanorama(false);
                                    } else {
                                      setPanorama({ lat: row.latitude, lng: row.longitude });
                                    }
                                  }}>
                                  {JSON.stringify(panorama) ==
                                  JSON.stringify({ lat: row.latitude, lng: row.longitude }) ? (
                                    <>
                                      <GoogleMapSVG /> Map view
                                    </>
                                  ) : (
                                    <>
                                      <StreetViewSVG /> Street view
                                    </>
                                  )}
                                </div>
                                <button className="tableCellContact">Contacts</button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          row[column.id]
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="tableEmpty">No records found</div>
      )}
    </>
  );
};

export default ListingTable;
