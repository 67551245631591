import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ReactComponent as ArrowCard } from '../../assets/img/cardArrow.svg';

import './ListingSlider.scss';

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} style={{ background: '#5796D2' }} onClick={onClick}>
      <ArrowCard />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowCard />
    </div>
  );
}

function CustomSlides({ children }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>{children}</Slider>
    </div>
  );
}

export default CustomSlides;
