export const placeholderData = [
  {
    address: 'Mock data: enter email to see real',
    latitude: 40.706711,
    longitude: -74.010457,
    distance: 0,
    price: 6500,
    sqft: 1418,
    dollar_sqft: 4.58,
    bedrooms: 2,
    baths: '1ba',
    property_type: 'Apartment',
    last_seen: '2024-02-21',
    flag: 'blue'
  },
  {
    address: 'Mock data: enter email to see real',
    latitude: 40.706711,
    longitude: -74.010457,
    distance: 0,
    price: 6950,
    sqft: 1200,
    dollar_sqft: 5.79,
    bedrooms: 2,
    baths: '1ba',
    property_type: 'Apartment',
    last_seen: '2024-01-04',
    flag: 'blue'
  },
  {
    address: 'Mock data: enter email to see real',
    latitude: 40.706711,
    longitude: -74.010457,
    distance: 0,
    price: 7250,
    sqft: 1524,
    dollar_sqft: 4.76,
    bedrooms: 2,
    baths: '2ba',
    property_type: 'Apartment',
    last_seen: '2023-09-05',
    flag: 'blue'
  },
  {
    address: 'Mock data: enter email to see real',
    latitude: 40.70648,
    longitude: -74.010167,
    distance: 0.03,
    price: 6795,
    bedrooms: 2,
    baths: '2ba',
    property_type: 'Apartment',
    last_seen: '2024-03-08',
    flag: 'blue'
  },
  {
    address: 'Mock data: enter email to see real',
    latitude: 40.706052,
    longitude: -74.011057,
    distance: 0.07,
    price: 6975,
    sqft: 1126,
    dollar_sqft: 6.19,
    bedrooms: 2,
    baths: '2ba',
    property_type: 'Apartment',
    last_seen: '2023-12-16',
    flag: 'blue'
  }
];

export const placeholderCheapestData = [
  {
    address: 'Mock data: enter email to see real',
    latitude: 40.706089,
    longitude: -74.011137,
    distance: 0.07,
    price: 5500,
    bedrooms: 2,
    baths: '1ba',
    property_type: 'apartment',
    last_seen: '2023-04-15',
    flag: 'green'
  },

  {
    address: 'Mock data: enter email to see real',
    latitude: 40.706028,
    longitude: -74.011765,
    distance: 0.11,
    price: 5950,
    sqft: 1338,
    dollar_sqft: 4.45,
    bedrooms: 2,
    baths: '2ba',
    property_type: 'Apartment',
    last_seen: '2024-01-14',
    flag: 'green'
  },
  {
    address: 'Mock data: enter email to see real',
    latitude: 40.705505,
    longitude: -74.009656,
    distance: 0.12,
    price: 5450,
    sqft: 762,
    dollar_sqft: 7.15,
    bedrooms: 2,
    baths: '1ba',
    property_type: 'Apartment',
    last_seen: '2023-11-02',
    flag: 'green'
  },
  {
    address: 'Mock data: enter email to see real',
    latitude: 40.707371,
    longitude: -74.012229,
    distance: 0.14,
    price: 5778,
    sqft: 1126,
    dollar_sqft: 5.13,
    bedrooms: 2,
    baths: '2ba',
    property_type: 'Apartment',
    last_seen: '2023-09-27',
    flag: 'green'
  },
  {
    address: 'Mock data: enter email to see real',
    latitude: 40.70585,
    longitude: -74.008826,
    distance: 0.14,
    price: 5325,
    sqft: 689,
    dollar_sqft: 7.73,
    bedrooms: 2,
    baths: '1ba',
    property_type: 'Apartment',
    last_seen: '2023-12-15',
    flag: 'green'
  }
];

export const columns = [
  { id: 'address', label: 'Property Address' },
  { id: 'propertyType', label: 'Property Type' },
  { id: 'distance', label: 'Distance' },
  { id: 'bedrooms', label: 'BR' },
  { id: 'bathrooms', label: 'Ba' },
  { id: 'price', label: 'Price' },
  { id: 'size', label: 'Sqft' },
  { id: 'priceSqft', label: '$/sqft' },
  { id: 'listDate', label: 'Last seen' }
];

export const columnsSpecial = [
  { id: 'Property Name', label: 'Property Name' },
  { id: 'Formatted Address', label: 'Address' },
  { id: 'date_collected', label: 'Verified On' },
  { id: 'Phone Number', label: 'Phone Number' },
  { id: 'Web Address', label: 'Web Address' },
  { id: 'Contact Us URL', label: 'Contact Us' }
];
