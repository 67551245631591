import React from 'react';

const Legend = ({ type, avarege, median, samples, specalsQty, radius }) => {
  return (
    <>
      <div className="resultsLegend">
        <div>Search results</div>
        {type === 'special' ? null : (
          <>
            <div>Average: ${avarege?.toLocaleString()}</div>
            <div>Median: ${median?.toLocaleString()}</div>
          </>
        )}
        <div>Samples: {type !== 'special' ? samples : specalsQty}</div>
        <div>Radius (in miles): {type !== 'special' ? radius : 3}</div>
      </div>
      <div className="resultsLegendColors">
        <div className="resultsLegendColorsTitle">Comparable Rents</div>
        <div className="resultsLegendColorsNear">
          <div className="resultsLegendBox"></div>Near budget
        </div>
        <div className="resultsLegendColorsBelow">
          <div className="resultsLegendBox"></div>Below budget
        </div>
        <div className="resultsLegendColorsSpecial">
          <div className="resultsLegendBox"></div>Specials
        </div>
        <div className="resultsLegendColorsSubTitle">(Above budget not shown)</div>
      </div>
    </>
  );
};

export default Legend;
