import React from 'react';
import './CounterInput.scss';
import { ReactComponent as MinusSvg } from '../../assets/img/minus.svg';
import { ReactComponent as PlusSvg } from '../../assets/img/plus.svg';

const CounterInput = ({ count, onIncrement, onDecrement, isDisabled }) => {
  return (
    <div className="counter">
      <button className="counterBtn" onClick={onDecrement} disabled={count === 1}>
        <MinusSvg />
      </button>
      <div className="counterNumber">{count}</div>
      <button className="counterBtn" onClick={onIncrement} disabled={count === 4}>
        <PlusSvg />
      </button>
    </div>
  );
};

export default CounterInput;
