import React, { useEffect, useRef } from 'react';
import logo from '../../assets/img/radial-logo.svg';

import './Header.scss';

const Header = () => {
  const navLinksRef = useRef<HTMLUListElement | null>(null);
  const burgerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleBurgerClick = () => {
      if (navLinksRef.current && burgerRef.current) {
        navLinksRef.current.classList.toggle('active');
        burgerRef.current.classList.toggle('active');
      }
    };

    if (burgerRef.current) {
      burgerRef.current.addEventListener('click', handleBurgerClick);
    }

    return () => {
      if (burgerRef.current) {
        burgerRef.current.removeEventListener('click', handleBurgerClick);
      }
    };
  }, []);

  return (
    <header className="header">
      <div className="container headerContainer">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <nav className="nav">
          <ul className="nav-links" ref={navLinksRef}>
            <li>
              <a href="https://www.rentometer.com/about">About Us</a>
            </li>
            <li>
              <a href="https://www.rentometer.com">Back to Rentometer.com</a>
            </li>
            <li className="nav-button">
              <a href="https://www.rentometer.com/feedback/new">Contact Us</a>
            </li>
          </ul>
          <div className="burger" ref={burgerRef}>
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
