import React, { useEffect, useState } from 'react';
import { getIPAddress } from '../../constans/helpers';
import useMediaQuery from '../../hooks/useMediaQuery';
import './RequestAccessMapModal.scss';

const RequestAccessMapModal = ({
  handleOpenListing,
  mean,
  modal,
  setModal,
  setBlur,
  setShowMobileLayout
}) => {
  const [email, setEmail] = useState('');
  const [allowNotifications, setAllowNotifications] = useState(false);
  const [allowNews, setAllowNews] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');

  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    // Add event listeners to disable scroll when the modal is open
    if (modal && isMobile) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      // Cleanup: remove event listeners when component unmounts
      document.body.style.overflow = '';
    };
  }, [modal]);

  const handleAllowNotificationsChange = (event) => {
    setAllowNotifications(Boolean(event.target.checked));
  };
  const handleAllowNewsChange = (event) => {
    setAllowNews(Boolean(event.target.checked));
  };

  const modalDisplay = modal ? (isMobile ? 'flex ' : 'block') : 'none';

  const handleBuildMap = async () => {
    setValidationMsg('');
    const idAddress = await getIPAddress();
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: email,
        userIPAddress: idAddress.ip,
        allowNotifications: allowNotifications,
        allowNews: allowNews,
        createdAt: new Date()
      })
    };

    const response = await fetch(
      'https://toolsapi.rentometer.com:3000/radial/verify-email',
      options
    );
    const msg = await response.json();

    if (response.ok) {
      handleOpenListing(mean);
      closeModal();
      setBlur(false);
    } else {
      setValidationMsg(msg.error);
    }
  };

  const closeModal = () => {
    if (isMobile) {
      setModal(false);
      setShowMobileLayout !== false ? setShowMobileLayout(true) : '';
    } else {
      setModal(false);
    }
  };

  const cancelModal = () => {
    setModal(false);
  };

  return (
    <div className={isMobile ? 'modalMobile' : 'modal'} style={{ display: modalDisplay }}>
      <div className="modalContainer">
        <div className="modalTitle">View listings</div>
        <div className="modalDescription">
          Please enter your email to view a sample of rentals that fit your budget
        </div>
        <label htmlFor="modalEmail" className="modalLabel">
          Your email
          <input
            type="email"
            id="modalEmail"
            placeholder="Enter email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </label>
        <div className="modalCheckbox">
          <label htmlFor="modalNotif" className="modalCheckboxItem">
            <input
              type="checkbox"
              id="modalNotif"
              checked={allowNotifications}
              onChange={handleAllowNotificationsChange}
            />
            <span className="checkmark"></span>
            I&apos;m interested in connecting with an expert to help me find my next rental
          </label>
          <label htmlFor="modalShare" className="modalCheckboxItem">
            <input
              type="checkbox"
              id="modalShare"
              checked={allowNews}
              onChange={handleAllowNewsChange}
            />
            <span className="checkmark"></span>
            I&apos;d like to receive news and announcements from your team
          </label>
        </div>
        <div className={isMobile ? 'modalButtonContainer' : ''}>
          {isMobile ? (
            <button className="modalButtonCancel" onClick={cancelModal}>
              Cancel
            </button>
          ) : null}
          <button className="modalButton" onClick={handleBuildMap}>
            Confirm
          </button>
        </div>

        <div className="modalError">{validationMsg}</div>
      </div>
    </div>
  );
};

export default RequestAccessMapModal;
