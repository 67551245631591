import cloneDeep from 'lodash/cloneDeep';

export const processFetchData = (data, currentPrice, mean) => {
  const clonedData = cloneDeep(data);
  const processedData = clonedData.nearby_properties
    .map((item) => {
      const priceDifference = ((item.price - currentPrice) / currentPrice) * 100;

      if (priceDifference >= -10 && priceDifference <= 10) {
        item.flag = 'blue';
      } else if (priceDifference >= -25 && priceDifference <= -10) {
        item.flag = 'green';
      } else {
        return null;
      }

      return item;
    })
    .filter(Boolean);

  const sortedPrices = [...clonedData.nearby_properties].sort((a, b) => b.price - a.price);
  const bigestGreen = processedData
    .filter((item) => item.flag === 'green')
    ?.sort((a, b) => b.price - a.price)[0];
  const top5Prices = sortedPrices
    .slice(0, 5)
    .filter(
      (property) => property.price > mean && (bigestGreen ? property.price > bigestGreen : true)
    );
  const filtredTop5Prices = top5Prices.filter((property) => property.price > mean);
  const isBudgetInTop5 =
    filtredTop5Prices.length !== 0 && currentPrice >= top5Prices[top5Prices.length - 1].price;

  if (processedData.length >= 5 || !isBudgetInTop5) {
    return {
      ...clonedData,
      nearby_properties: processedData
    };
  }

  const newData = top5Prices
    .map((item) => {
      const existsInProcessedData = processedData.some(
        (processedItem) =>
          processedItem.address === item.address && processedItem.price === item.price
      );

      if (!existsInProcessedData) {
        item.flag = 'blue';
        return item;
      } else {
        return null;
      }
    })
    .filter((item) => item !== null)
    .sort((a, b) => b.price - a.price);

  const remainingSpaces = 5 - processedData.length;

  const additionalItems = newData.slice(0, remainingSpaces);

  return {
    ...clonedData,
    nearby_properties: [...additionalItems, ...processedData]
  };
};

export const findCheapestProperties = (data, currentPrice) => {
  const clonedData = cloneDeep(data);
  const processedData = clonedData.nearby_properties
    .map((item) => {
      const priceDifference = ((item.price - currentPrice) / currentPrice) * 100;

      if (priceDifference >= -10 && priceDifference <= 10) {
        item.flag = 'blue';
      } else if (priceDifference >= -25 && priceDifference <= -10) {
        item.flag = 'green';
      } else {
        return null;
      }

      return item;
    })
    .filter(Boolean);

  const sortedPrices = processedData.sort((a, b) => a.price - b.price);
  const top5Cheapest = sortedPrices.slice(0, 5);
  return {
    ...clonedData,
    nearby_properties: top5Cheapest
  };
};

export function calculateAngle(percentage) {
  const minAngle = -13; // Angle for 0%
  const maxAngle = 195; // Angle for 100%
  const midAngle = 90; // Angle for 50%

  // Calculate the angle based on the percentage using a linear interpolation
  if (percentage <= 50) {
    return minAngle + (midAngle - minAngle) * (percentage / 50);
  } else {
    return midAngle + (maxAngle - midAngle) * ((percentage - 50) / 50);
  }
}

export const getIPAddress = async () => {
  const res = await fetch('https://api.ipify.org/?format=json');
  const data = await res.json();
  return data;
};

export const calculatePercentile = (min, max, percentage) => {
  return (max - min) * percentage + min;
};
